import React, { useState } from "react";
import { useDataContext } from "../../../State/context/DataContext";
import Table from "./Table";
import { useFlowContext } from "../../../State/context/FlowContext";
import { userService } from "../../../Services/user.service";

const header = [
  { name: "Full Name", ratio: 25 },
  { name: "Email Address", ratio: 25 },
  { name: "Role", ratio: 17.5 },
  { name: "Status", ratio: 17.5 },
];

const CustomCell = ({ val, className }) => {
  return <span className={className}>{val}</span>;
};

const UserTable = ({ showUser }) => {
  const { users, setUsers } = useDataContext();
  const service = new userService();
  const { setRowId, setEntity } = useFlowContext();
  const [checkedList, setCheckedList] = useState([]);
  const append = (id) => setCheckedList([...checkedList, id]);
  const remove = (id) =>
    setCheckedList([...checkedList.filter((c) => c === id)]);
  // console.log(users);
  const viewRow = (id) => {
    setRowId(id);
    // console.log("id", id);
    const item = users?.data?.find((item) => item.uuid === id);
    // console.log("item", item);
    setEntity({
      id: item?.id,
      name: item?.first_name[0] + item?.last_name[0],
      descrption: { label: "", value: "" },
      type: "regular",
      hasActions: true,
      activate: () => {},
      deactivate: () => {},
    });
    showUser();
  };

  const switchPage = async (page) => {
    const res = await service.getUserPages(page, 10);
    // console.log(res.data);
    if (res.message === "success") {
      setUsers(res.data);
    }
    return { message: res.message };
  };

  const createdata = (
    first_name,
    last_name,
    // middle_name,
    id,
    email,
    role,
    status
  ) => {
    let full_name = first_name + " " + last_name + " ";
    let status_ = status === 1 ? "active" : "inactive";

    const getParam = (val) => {
      if (val === "active") {
        return (
          <CustomCell
            val={val}
            className={
              "w-fit p-3 bg-[#5db86c1a] text-[#5DB86C] h-[36px] flex items-center justify-center rounded-[36px]"
            }
          />
        );
      } else if (val === "inactive") {
        return (
          <CustomCell
            val={val}
            className={
              "w-fit p-3 bg-[#da34420a] text-[#da3442] h-[36px] flex items-center justify-center rounded-[36px]"
            }
          />
        );
      } else {
        // console.log(val);
        return val;
      }
    };
    const row = [
      { name: getParam(full_name), id: id, root: "Full Name" },
      { name: getParam(email), id: id, root: "Email Address" },
      { name: getParam(role), id: id, root: "Role" },
      { name: getParam(status_), id: id, root: "Role" },
    ];
    return row;
  };

  const data =
    users && users?.data
      ? users?.data?.map((t) =>
          createdata(
            t?.first_name,
            t?.last_name,
            "",
            t?.username,
            t?.roles[0].name,
            t?.verified
          )
        )
      : [];
  
  return (
    <Table
      checkedList={checkedList}
      append={append}
      remove={remove}
      data={data}
      header={header}
      viewRow={viewRow}
      paginationData={{
        limit: users?.limit,
        page: users?.page,
        total: users?.total,
        "more-pages": users["more-pages"],
        navigatePage: switchPage,
      }}
      deleteParam={{
        title: "Role",
        label:
          "You are about to delete a role. Please click confirm to delete the role",
      }}
    />
  );
};

export default UserTable;
