import React, { useState, createContext, useContext } from "react";

const reactContext = createContext();

const AuthContextProvider = (props) => {
  const [profile, setProfile] = useState(
    localStorage.user ? JSON.parse(localStorage.user) : {}
  );
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  
  return (
    <reactContext.Provider
      value={{
        profile,
        isLoggedIn,
        setProfile,
        setIsLoggedIn,
      }}
      {...props}
    />
  );
};

const useAuthContext = () => {
  const context = useContext(reactContext);
  return context;
};

export { AuthContextProvider, useAuthContext };
